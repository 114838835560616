<video
  src="../../../../assets/LolliCash-Video.mp4"
  autoplay
  loop
  playsinline
  class="image-bg rounded-2"
></video>

<div
  class="modal fade" id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  #myModal
>
<div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content bg-dark">
      <div class="modal-body p-0 pt-3">
        <h1 class="text-center text-danger">{{ EventList }}</h1>
  
        <!-- Removed the carousel-related divs -->
        <div class="text-center text-danger">
          <h1>{{ mediaEventList.event_name }}</h1> <!-- Display event name if exists -->
          <img
            [src]="imageUrl + mediaEventList.media"
            alt="Event Image"
            width="100%"
            height="100%"
            style="object-fit: cover"
          />
        </div>
  
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            (click)="closeModel()"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-light"
            (click)="buyTicket()"
          >
            Buy a Ticket
          </button>
        </div>
      </div>
    </div>
  </div>