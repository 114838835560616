import { Routes } from "@angular/router";
import { LoginComponent } from "./components/sign_up/login/login.component";
import { SignUpComponent } from "./components/sign_up/sign-up.component";
import { DashboradComponent } from "./components/dashborad/dashborad.component";
import { HistoryComponent } from "./components/history/history.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { BankInformationComponent } from "./components/bank-information/bank-information.component";
import { SendMoneyComponent } from "./components/send-money/send-money.component";
import { RequestMoneyComponent } from "./components/request-money/request-money.component";
import { AccountSupportComponent } from "./components/account-support/account-support.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./components/terms-conditions/terms-conditions.component";
import { TransactionLimitComponent } from "./components/transaction-limit/transaction-limit.component";
import { AddMoneyComponent } from "./components/add-money/add-money.component";
import { DepositMoneyComponent } from "./components/deposit-money/deposit-money.component";
import { AllTransactionComponent } from "./components/all-transaction/all-transaction.component";
import { EventTicketComponent } from "./components/events/event-ticket/event-ticket.component";
import { EventTicketListComponent } from "./components/events/event-ticket-list/event-ticket-list.component";
import { SelectEventTicketComponent } from "./components/events/select-event-ticket/select-event-ticket.component";

export const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  
  {
    path: ":event_name",
    component: LoginComponent,
  },

  {
    path: "sign_up",
    component: SignUpComponent,
  },
  {
    path: "dashboard",
    component: DashboradComponent,
  },
  {
    path: "history",
    component: HistoryComponent,
  },
  {
    path: "about_us",
    component: AboutUsComponent,
  },
  {
    path: "profile",
    component: ProfileComponent,
  },
  {
    path: "bank_information",
    component: BankInformationComponent,
  },
  {
    path: "send_money",
    component: SendMoneyComponent,
  },
  {
    path: "request_money",
    component: RequestMoneyComponent,
  },
  {
    path: "account_support",
    component: AccountSupportComponent,
  },
  {
    path: "privacy_policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "terms_condotions",
    component: TermsConditionsComponent,
  },
  {
    path: "transaction_limit",
    component: TransactionLimitComponent,
  },
  {
    path: "add_money",
    component: AddMoneyComponent,
  },
  {
    path: "deposit_money",
    component: DepositMoneyComponent,
  },
  {
    path: "all_transactions",
    component: AllTransactionComponent,
  },
  {
    path: "event_ticket_list/:user_id",
    component: EventTicketComponent,
  },
  {
    path: "select_event_ticket/:user_id",
    component: SelectEventTicketComponent,
  },
  {
    path: "ticket_list/:event_id",
    component: EventTicketListComponent,
  },
];
