import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSelectModule } from "@angular/material/select";
import { DropdownModule } from "primeng/dropdown";
import { first } from "rxjs";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ApiService } from "../../../service/api.service";
import { environment } from "../../../../environments/environment";
import { SharedDataService } from "../../../service/shared/shared-data.service";

@Component({
  selector: 'app-select-event-ticket',
  standalone: true,
  imports: [
    MatSelectModule,
    DropdownModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
  ],
templateUrl: './select-event-ticket.component.html',
  styleUrl: './select-event-ticket.component.css'
})
export class SelectEventTicketComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  @ViewChild("myModal") myModal: ElementRef | undefined;

  constructor(
    private service: ApiService,
    public router: Router,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute
  ) {
    this.loginForm = this.formBuilder.group({
      mobileNo: ["", Validators.required],
      countryISO: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  countryCodes: any = [];
  eventData: any;
  imageUrl: any;
  selectEventId: any;
  ngOnInit(): void {
    this.selectEventId = this.route.snapshot.paramMap.get("user_id");
    if(this.selectEventId){
      this.eventTicketList();
    }
    this.service
      .getCountriesCode()
      .pipe(first())
      .subscribe((response: any) => {
        this.countryCodes = response;
      });
    this.imageUrl = environment.image_url;
  }

  ngAfterViewInit() {
    this.myModal?.nativeElement.classList.add("show");
    if (this.myModal?.nativeElement) {
      this.myModal.nativeElement.style.display = "block";
    }
    this.cdr.detectChanges();
  }

  closeModel() {
    if (this.myModal?.nativeElement) {
      this.myModal.nativeElement.classList.remove("show");
      this.myModal.nativeElement.style.display = "none";
      this.cdr.detectChanges();
    }
  }

  buttonDisabled: boolean = false;
  mediaEventList: any;
  EventList: any;
  eventTicketList() {
    this.service.event_list().pipe(first()).subscribe(
      (response: any) => {
        if (response?.media_list && response.media_list.length > 0) {
          this.mediaEventList = response.media_list; // 3. Store the media event list from the response
          this.EventList = response.data[0].event_name; // 3. Store the media event list from the response
          console.log('this.mediaEventList ', this.mediaEventList);
          this.mediaEventList = this.mediaEventList.find((event: any) => event.event_id === String(this.selectEventId));
          console.log('this.mediaEventList ', this.mediaEventList);

        } else {
          console.error("No data available in the response.");
          this.mediaEventList = [];
        }
      },
      (error) => {
        console.error("An error occurred while fetching event data:", error);
        this.mediaEventList = [];
      }
    );
  }

  onSubmit() {
    let countryCountry = this.countryCodes.find(
      (country: any) => country.code == this.loginForm.get("countryISO")?.value
    ).dial_code;
    console.log(countryCountry);

    let update = "1";
    const formData = new FormData();
    formData.append("user_mobile", this.loginForm.get("mobileNo")?.value);
    formData.append("user_password", this.loginForm.get("password")?.value);
    formData.append("updte", update);
    formData.append("country_code", countryCountry.replace("+", ""));
    formData.append("country_iso", this.loginForm.get("countryISO")?.value);
    // formData.append('device_type', '3');
    this.service
      .login(formData)
      .pipe(first())
      .subscribe((response: any) => {
        console.log(response);
        if (response) {
          if (response.error == 0) {
            localStorage.setItem("user", JSON.stringify(response.data));
            this.service.notify.showSuccess(response.error_msg);
            this.router.navigate(["/dashboard"]);
          } else {
            return this.service.notify.showSuccess(response.error_msg);
          }
        }
      });
  }

  buyTicket() {
      this.router.navigate([`/ticket_list/${this.selectEventId}`]);
  }
  
  getActiveCarouselIndex(): number {
    const items = document.querySelectorAll(".carousel-item");
    for (let i = 0; i < items.length; i++) {
      if (items[i].classList.contains("active")) {
        return i;
      }
    }
    return 0; // Default to the first item if no active item is found
  }
}
